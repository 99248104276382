// extracted by mini-css-extract-plugin
export var blink = "styles-module--blink--VD6oB";
export var card_blog = "styles-module--card_blog--nasQe";
export var featured_background = "styles-module--featured_background--KyVje";
export var featured_image = "styles-module--featured_image--oCBLP";
export var featured_title = "styles-module--featured_title--oLfgA";
export var m_blog = "styles-module--m_blog--Cdyn2";
export var page_title = "styles-module--page_title--s2uK4";
export var pagination = "styles-module--pagination--D4xt6";
export var pagination_current = "styles-module--pagination_current--5zgxt";
export var post_link = "styles-module--post_link--+acMZ";
export var post_text = "styles-module--post_text--ZaJiR";
export var post_title = "styles-module--post_title--1xCzt";
export var related_blog = "styles-module--related_blog--GG5HO";
export var related_footer = "styles-module--related_footer--oNyqV";
export var related_title = "styles-module--related_title--4-mc4";
export var searchbox = "styles-module--searchbox---Jx4d";
export var sidebar = "styles-module--sidebar--1dkWl";
export var text_content = "styles-module--text_content--xL7sE";