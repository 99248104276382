import React, { Fragment } from 'react'
import { Link, graphql } from 'gatsby'
import get from 'lodash/get'
import { Helmet } from 'react-helmet'
import Layout from '../components/Layout'
import { GatsbyImage } from 'gatsby-plugin-image'
import * as myStyles from './styles.module.scss'

// Needs to be class component in order to pass variables to GraphQL query
// Static queries, do not accept variables
// https://www.gatsbyjs.com/docs/how-to/querying-data/use-static-query/#known-limitations
interface StateProps {
    pageContext: PageContext
}

interface PageContext {
    currentPage: number
    numPages: number
}

const styles: any = myStyles

class BlogListTemplate extends React.Component<StateProps> {
    // componentDidMount() {
    //     setTimeout(function () {
    //         document.querySelector<HTMLInputElement>('.searchboxinput').focus()
    //         document.querySelector<HTMLInputElement>('.searchboxinput').select()
    //     }, 100)
    // }
    //  TODO: Improve logic of pagination
    render() {
        const pageSEO = get(this, 'props.data.allContentfulPages.edges[0].node')

        // PAGINATION
        const { currentPage, numPages } = this.props.pageContext
        // Get current page from window
        let pageNumber
        // To prevent SSR errors
        if (typeof window !== 'undefined') {
            pageNumber = window.location.href.substring(window.location.href.lastIndexOf('/') - 1)
            pageNumber = parseInt(pageNumber[0])
            // For 2 digits (>10 in pagination)
            let secondDigitOfNumber = window.location.href.substring(
                window.location.href.lastIndexOf('/') - 2,
            )
            let integerOfSecondDigitOfNumber = parseInt(secondDigitOfNumber[0])
            if (!Number.isNaN(integerOfSecondDigitOfNumber)) {
                pageNumber = integerOfSecondDigitOfNumber + '' + pageNumber
                pageNumber = parseInt(pageNumber)
            }
        }
        let pagination

        // Parse it
        if (Number.isNaN(pageNumber) || !pageNumber) {
            // Case for page 1
            pagination = (
                <Fragment>
                    <ul className={`pagination ${styles.pagination}`}>
                        <Link to={'/blog/'} rel='canonical'>
                            <li
                                className={`pagination__item pagination__item--current ${styles.pagination_current}`}>
                                1
                            </li>
                        </Link>
                        <Link to={`/blog/2/`} rel='next'>
                            <li className='pagination__item pagination__item--next'>2</li>
                        </Link>
                        <Link to={`/blog/3/`}>
                            <li className='pagination__item pagination__item--next'>3</li>
                        </Link>
                        <Link to={`/blog/2/`} rel='next'>
                            <li className='pagination__item pagination__item--next'>»</li>
                        </Link>
                        <Link to={`/blog/${numPages}/`}>
                            <li className='pagination__item pagination__item--next'>»»</li>
                        </Link>
                    </ul>
                </Fragment>
            )
        } else if (pageNumber === numPages) {
            // Case for last page
            pagination = (
                <Fragment>
                    <ul className={`pagination ${styles.pagination}`}>
                        <Link to={`/blog/`}>
                            <li className='pagination__item pagination__item--next'>««</li>
                        </Link>
                        <Link to={`/blog/${pageNumber - 1}/`} rel='prev'>
                            <li className='pagination__item pagination__item--next'>«</li>
                        </Link>
                        <Link to={`/blog/${pageNumber - 1}/`} rel='prev'>
                            <li className='pagination__item pagination__item--next'>
                                {pageNumber - 1}
                            </li>
                        </Link>
                        <Link to={`/blog/${pageNumber}/`} rel='canonical'>
                            <li
                                className={`pagination__item pagination__item--current ${styles.pagination_current}`}>
                                {pageNumber}
                            </li>
                        </Link>
                    </ul>
                </Fragment>
            )
        } else {
            // Cases in the middle
            pagination = (
                <Fragment>
                    <ul className={`pagination ${styles.pagination}`}>
                        <Link to={`/blog/`}>
                            <li className='pagination__item pagination__item--next'>««</li>
                        </Link>
                        {/* Case for 2 */}
                        {pageNumber === 2 ? (
                            <Fragment>
                                <Link to={`/blog/`} rel='prev'>
                                    <li className='pagination__item pagination__item--next'>«</li>
                                </Link>

                                <Link to={`/blog/`} rel='prev'>
                                    <li className='pagination__item pagination__item--next'>
                                        {pageNumber - 1}
                                    </li>
                                </Link>
                            </Fragment>
                        ) : (
                            <Fragment>
                                <Link to={`/blog/${pageNumber - 1}/`} rel='prev'>
                                    <li className='pagination__item pagination__item--next'>«</li>
                                </Link>

                                <Link to={`/blog/${pageNumber - 1}/`} rel='prev'>
                                    <li className='pagination__item pagination__item--next'>
                                        {pageNumber - 1}
                                    </li>
                                </Link>
                            </Fragment>
                        )}

                        <Link to={`/blog/${pageNumber}/`} rel='canonical'>
                            <li
                                className={`pagination__item pagination__item--current ${styles.pagination_current}`}>
                                {pageNumber}
                            </li>
                        </Link>

                        <Link to={`/blog/${pageNumber + 1}/`} rel='next'>
                            <li className='pagination__item pagination__item--next'>
                                {pageNumber + 1}
                            </li>
                        </Link>
                        <Link to={`/blog/${pageNumber + 1}/`} rel='next'>
                            <li className='pagination__item pagination__item--next'>»</li>
                        </Link>
                        <Link to={`/blog/${numPages}/`}>
                            <li className='pagination__item pagination__item--next'>»»</li>
                        </Link>
                    </ul>
                </Fragment>
            )
        }

        const blog = get(this, 'props.data.allContentfulLensesBlog.edges')
        const tags = get(this, 'props.data.allContentfulTags.edges')
        const authors = get(this, 'props.data.allContentfulAuthor.edges')

        const categories = get(this, 'props.data.allContentfulCategories.edges')
        // Something really old to init
        let latestDate = new Date(1 / 1 / 2000)
        let year = latestDate.getFullYear()
        let month = latestDate.getMonth()
        month++
        // Getting the month from the date & properly formatting - used in the url
        let monthString = ('0' + month).slice(-2)
        let latestBlog

        const dateOptions = {
            year: 'numeric',
            month: 'short',
            day: '2-digit',
        } as const

        // AUTHOR NAMES
        let authorNames = []
        blog.forEach((authorNode) => {
            authorNames.push(authorNode.node.author.name)
        })

        return (
            <Layout>
                <Helmet>
                    <title>{`${pageSEO.title.title} ${currentPage}`}</title>
                    <meta name='twitter:text:title' content={pageSEO.title.title} />
                    <meta
                        name='description'
                        content={`${pageSEO.description.description} ${currentPage}`}
                    />
                    <meta property='og:description' content={pageSEO.description.description} />
                    <meta
                        property='twitter:description'
                        content={pageSEO.description.description}
                    />
                    <meta name='keywords' content={`${pageSEO.keywords.keywords} ${currentPage}`} />
                    <meta name='twitter:card' content='summary_large_image' />
                    <meta name='twitter:site' content='@lensesio' />
                    <meta property='og:title' content={pageSEO.title.title} />
                    <meta property='twitter:title' content={pageSEO.title.title} />
                    <meta property='og:image' content={'https:' + pageSEO.oGimage.fluid.src} />
                    <meta property='twitter:image' content={'https:' + pageSEO.oGimage.fluid.src} />
                    <link
                        rel='stylesheet'
                        href='https://cdn.jsdelivr.net/npm/docsearch.js@2/dist/cdn/docsearch.min.css'
                    />
                    <script
                        type='text/javascript'
                        src='https://cdn.jsdelivr.net/npm/docsearch.js@2/dist/cdn/docsearch.min.js'
                        // onLoad does not expect strings,
                        // but string is required for docsearch to be functional
                        // @ts-expect-error
                        onLoad="docsearch({apiKey: '32e017aaf6842be76ecbac45550b35fa',
                                     appId: '84ASK1T3ST',
                                     indexName: 'dev_BLOG',
                                     inputSelector: '#searchbox input',
                                     debug: true})"
                        async></script>
                </Helmet>
                <div className={styles.m_blog}>
                    <section className={`pb-5 pt-5 ${styles.featured_background}`}>
                        <div className='container pt-5'>
                            <div className='row mt-5 mb-3'>
                                <div className='col-md-12 col-md-12 text-center pt-0'>
                                    <h1
                                        className={`exclude-algolia-title h2-like ${styles.page_title}`}>
                                        Lenses team is typing
                                    </h1>
                                </div>

                                {/* <div className='col-12'>
                                    <div id='searchbox' role='search' className='w-100 d-flex'>
                                        <input
                                            type='text'
                                            className={`pl-0 border-0 searchboxinput ${styles.searchbox}`}
                                            name='q'
                                            autoComplete='off'
                                            placeholder='Search blog'
                                            autoFocus
                                        />{' '}
                                        <i
                                            style={{ marginTop: '15px', color: '#666666' }}
                                            className='fa fa-search ml-2'></i>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </section>

                    {/* Logic for latest blog */}
                    {blog.map(({ node }) => {
                        // Create date object from dates in JSON & compare
                        let tempDate = node.date
                        let dateToCompare = new Date(tempDate)
                        // Get latest date, set latest blog
                        if (dateToCompare > latestDate) {
                            latestDate = dateToCompare
                            latestBlog = node
                            year = latestDate.getFullYear()
                            month = latestDate.getMonth()
                            month++
                            // Getting the month from the date & properly formatting - used in the url
                            monthString = ('0' + month).slice(-2)
                        }
                    })}
                    <section className='algolia-container'>
                        <div className='container-fluid'>
                            <div className='mt-3 pt-3'>
                                {/* For else logic */}

                                <div className='row'>
                                    <div className='col-xl-11 col-lg-11 col-md-12 col-11 px-5 mb-5 ml-3 '>
                                        <div className='row align-items-center'>
                                            <div className='col-md-6 col-lg-7 col-xl-6 offset-xl-1 offset-lg-0  col-12 px-0'>
                                                <a
                                                    href={`/blog/${year}/${monthString}/${latestBlog.slug}/`}>
                                                    <div className='d-none d-sm-block'>
                                                        <GatsbyImage
                                                            className={`${styles.featured_image}`}
                                                            image={
                                                                latestBlog.heroimage.gatsbyImageData
                                                            }
                                                            alt={latestBlog.description}
                                                        />
                                                    </div>

                                                    <div className='d-sm-none d-md-none d-lg-none d-xl-none'>
                                                        <GatsbyImage
                                                            className={`${styles.featured_image}`}
                                                            image={
                                                                latestBlog.heroimage.gatsbyImageData
                                                            }
                                                            alt={latestBlog.description}
                                                        />
                                                    </div>
                                                </a>
                                            </div>

                                            <div className='col-md-6 col-lg-5  col-xl-4 col-12'>
                                                <div className='pl-4 mobile-padding-reset'>
                                                    <a
                                                        href={`/blog/${year}/${monthString}/${latestBlog.slug}/`}>
                                                        <div className='pt-4 lg-reset-margin-top'>
                                                            <h2
                                                                className={`lh-2-3 on-hover-red pb-1 ${styles.featured_title}`}>
                                                                {latestBlog.title}
                                                            </h2>
                                                        </div>
                                                        <p
                                                            className={`paragraph-text ${styles.post_text}`}>
                                                            {' '}
                                                            {latestBlog.subtitle.substring(0, 130)}
                                                        </p>
                                                    </a>
                                                    <div
                                                        className='mt-5 d-flex pt-5 lg-reset-margin-top lg-reset-padding-top mobile-margin-reset'
                                                        style={{ bottom: '0px' }}>
                                                        {latestBlog.author.display ? (
                                                            <Fragment>
                                                                <p className='d-flex'>
                                                                    <GatsbyImage
                                                                        className={`author-photo-blog mr-3 ${styles.author_image}`}
                                                                        image={
                                                                            latestBlog.author.image
                                                                                .gatsbyImageData
                                                                        }
                                                                        alt={latestBlog.title}
                                                                    />
                                                                </p>
                                                                <p
                                                                    className={`pl-2 f-14 mr-3 fw-600 ${styles.post_text}`}>
                                                                    <p
                                                                        className={`d-flex ${styles.post_link}`}>
                                                                        <span className='primary-text'>
                                                                            {latestBlog.author.name}
                                                                        </span>
                                                                    </p>
                                                                    {latestDate.toLocaleDateString(
                                                                        'en-US',
                                                                        dateOptions,
                                                                    )}
                                                                </p>
                                                            </Fragment>
                                                        ) : (
                                                            <Fragment>
                                                                <GatsbyImage
                                                                    className='author-photo-blog mr-3'
                                                                    image={
                                                                        latestBlog.author.image
                                                                            .gatsbyImageData
                                                                    }
                                                                    alt={latestBlog.title}
                                                                />
                                                                <p
                                                                    className={`pl-2 f-14 mr-3 fw-600 ${styles.post_text}`}>
                                                                    {' '}
                                                                    {latestBlog.author.name}
                                                                    <br />
                                                                    {latestDate.toLocaleDateString(
                                                                        'en-US',
                                                                        dateOptions,
                                                                    )}
                                                                </p>
                                                            </Fragment>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    {/* AFTER SINGLE DISPLAY */}
                    <div className='d-flex flex-wrap  mobile-margin-reset'>
                        <div className='offset-lg-1 offset-0'></div>
                        <div className='col-lg-12 col-12 mobile-padding-reset'>
                            <div className='row mobile-margin-reset'>
                                {blog.map(({ node }, index) => {
                                    // Dont DISPLAY THE ONE IN HEADER
                                    if (index !== 0) {
                                        let tempDate = node.date
                                        let dateToDisplay = new Date(tempDate)
                                        let year = dateToDisplay.getFullYear()
                                        let month = dateToDisplay.getMonth()
                                        month++
                                        // Getting the month from the date & properly formatting - used in the url
                                        let monthString = ('0' + month).slice(-2)
                                        return (
                                            <div
                                                className='col-md-4 col-sm-12 offset-sm-0 offset-md-0 offset-1 mt-0 p-5 col-10 scale-on-hover mobile-padding-reset mobile-margin-top'
                                                key={node.id}>
                                                <div className={styles.card_blog}>
                                                    <a
                                                        href={`/blog/${year}/${monthString}/${node.slug}/`}>
                                                        <GatsbyImage
                                                            className='w-100'
                                                            image={node.heroimage.gatsbyImageData}
                                                            alt='Blog list'
                                                        />
                                                    </a>
                                                    <div className='height-250 p-4'>
                                                        <a
                                                            href={`/blog/${year}/${monthString}/${node.slug}/`}>
                                                            <h2
                                                                className={`paragraph-title f-18 pb-3 lh-1-3 ${styles.post_title}`}>
                                                                {node.title}
                                                            </h2>
                                                            <div className='mt-1'>
                                                                <p
                                                                    className={`paragraph-text ${styles.post_text}`}>
                                                                    {' '}
                                                                    {node.subtitle.substring(0, 70)}
                                                                    ...
                                                                </p>
                                                            </div>
                                                        </a>
                                                        <div className='d-flex blog-image-author blog-image-author-static'>
                                                            {node.author.display ? (
                                                                <Fragment>
                                                                    <p className='d-flex'>
                                                                        <GatsbyImage
                                                                            className='author-photo-blog mr-3'
                                                                            image={
                                                                                node.author.image
                                                                                    .gatsbyImageData
                                                                            }
                                                                            alt={node.author.name}
                                                                        />
                                                                    </p>
                                                                    <p
                                                                        className={`pl-2 f-14 mr-3 fw-600 ${styles.post_text}`}>
                                                                        <p className='d-flex'>
                                                                            <span
                                                                                className={`primary-text ${styles.post_text}`}>
                                                                                {node.author.name}
                                                                            </span>
                                                                        </p>

                                                                        {dateToDisplay.toLocaleDateString(
                                                                            'en-US',
                                                                            dateOptions,
                                                                        )}
                                                                    </p>
                                                                </Fragment>
                                                            ) : (
                                                                <Fragment>
                                                                    <GatsbyImage
                                                                        className='author-photo-blog mr-3'
                                                                        image={
                                                                            node.author.image
                                                                                .gatsbyImageData
                                                                        }
                                                                        alt={node.author.name}
                                                                    />
                                                                    <p
                                                                        className={`pl-2 f-14 mr-3 fw-600 ${styles.post_text}`}>
                                                                        {node.author.name}
                                                                        <br />
                                                                        {dateToDisplay.toLocaleDateString(
                                                                            'en-US',
                                                                            dateOptions,
                                                                        )}
                                                                    </p>
                                                                </Fragment>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                })}
                                {/* END OF FOR ABOVE ^ */}
                            </div>
                        </div>

                        <div
                            className={`col-md-2 col-10 d-none d-lg-block ${styles.sidebar}`}
                            id='sidebar'>
                            <div id='scroller-anchor'></div>
                            <div id='scroller' style={{ zIndex: 1 }}>
                                <p className='blog-list-title mb-1'>Categories</p>
                                <a className='blog-list-filter' href='/blog/'>
                                    {' '}
                                    All{' '}
                                </a>
                                <br />
                                {categories.map((category) => {
                                    if (category.node.lenses_blog) {
                                        return (
                                            <Link
                                                key={category.node.id}
                                                className='blog-list-filter'
                                                to={`/categories/${category.node.categoryTitle.toLowerCase()}/`}
                                                rel='canonical'>
                                                {category.node.categoryTitle}
                                                <br />
                                            </Link>
                                        )
                                    }
                                })}
                                <br />
                                <p className='blog-list-title mb-1'>Authors</p>
                                {/* AUTHORS */}
                                <a className='blog-list-filter' href='/blog/'>
                                    {' '}
                                    All
                                    <br />
                                </a>
                                {authors.map((author) => {
                                    if (author.node.display) {
                                        if (author.node.lenses_blog) {
                                            return (
                                                <Link
                                                    key={author.node.id}
                                                    className='blog-list-filter'
                                                    to={`/author/${author.node.name
                                                        .replace(/\s+/g, '-')
                                                        .toLowerCase()}/`}>
                                                    <br />
                                                </Link>
                                            )
                                        }
                                    }
                                })}
                                <br />
                                <p className='blog-list-title mb-1'>Tags</p>
                                <a className='blog-list-filter' href='/blog/'>
                                    {' '}
                                    All{' '}
                                </a>
                                <br />
                                {tags.map((tag) => {
                                    if (tag.node.lenses_blog) {
                                        return (
                                            <Link
                                                key={tag.node.id}
                                                className='blog-list-filter'
                                                to={`/tags/${tag.node.tagTitle.toLowerCase()}/`}>
                                                {tag.node.tagTitle}
                                                <br />
                                            </Link>
                                        )
                                    }
                                })}
                            </div>
                        </div>
                    </div>
                    <div className='d-flex flex-wrap my-5 '>
                        <div className='mx-auto'>{pagination}</div>
                    </div>
                </div>
            </Layout>
        )
    }
}

export default BlogListTemplate

export const pageQuery = graphql`
    query LensesBlogListTemplateIndexQuery($skip: Int!, $limit: Int!) {
        site {
            siteMetadata {
                title
            }
        }
        allContentfulCategories {
            edges {
                node {
                    id
                    categoryTitle
                    lenses_blog {
                        categories {
                            categoryTitle
                        }
                    }
                }
            }
        }
        allContentfulTags {
            edges {
                node {
                    tagTitle
                    id
                    lenses_blog {
                        tags {
                            tagTitle
                        }
                    }
                }
            }
        }
        allContentfulAuthor {
            edges {
                node {
                    id
                    name
                    display
                    lenses_blog {
                        author {
                            name
                        }
                    }
                }
            }
        }
        allContentfulLensesBlog(limit: $limit, skip: $skip, sort: { fields: date, order: DESC }) {
            edges {
                node {
                    title
                    subtitle
                    slug
                    id
                    date
                    description
                    heroimage {
                        gatsbyImageData(layout: FULL_WIDTH)
                    }
                    author {
                        name
                        display
                        image {
                            gatsbyImageData(layout: FULL_WIDTH)
                        }
                    }
                }
            }
        }
        allContentfulPages(filter: { pageReference: { pageIdentifier: { eq: "Blog" } } }) {
            edges {
                node {
                    title {
                        title
                    }
                    keywords {
                        keywords
                    }
                    description {
                        description
                    }
                    oGimage {
                        fluid(quality: 100) {
                            src
                        }
                    }
                }
            }
        }
    }
`
